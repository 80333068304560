<template>
  <div>
    <Header :msg="$t('header_message2')"></Header>
    <HeroSection>
      <template #text>
        <div class="sm:text-center lg:text-left lg:my-32">
          <h2
            class="text-2xl tracking-tight leading-loose font-extrabold text-red-700 sm:text-3xl sm:leading-tight"
          >
            {{ $t('missing_page') }}
          </h2>
          <div class="mt-5 sm:mt-12 flex">
            <router-link
              :to="{ name: 'Home' }"
              class="btn btn-primary lg:py-5"
              >{{ $t('return_to_search_page') }}</router-link
            >
          </div>
        </div>
      </template>
      <template #img>
        <img
          class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          :alt="$t('hero_image')"
          src="../assets/images/b.jpg"
        />
      </template>
    </HeroSection>
  </div>
</template>
<script>
import Header from '@/components/Header'
import HeroSection from '@/components/HeroSection'

export default {
  components: { HeroSection, Header }
}
</script>
