<template>
  <div class="bg-gray-100 w-100 py-8">
    <div class="max-w-4xl m-auto flex flex-wrap justify-center items-center">
      <a href="https://www.gunb.gov.pl/">
        <img style="max-width: 150px;" :alt="$t('header_logo')" src="../assets/logo.png" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  }
}
</script>
