<template>
  <div class="relative bg-white overflow-hidden">
    <div class="container">
      <div class="relative z-10 lg:max-w-2xl lg:w-full">
        <!-- This part was responsible for diagonal right side - remove if customer don`t want to come back to previous layout -->
        <!-- <svg
          class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
          fill="currentColor"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg> -->
        <main class="mx-auto max-w-screen-xl px-4 lg:px-8 py-6 lg:py-32">
          <slot name="text"></slot>
        </main>
      </div>
    </div>
    <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <slot name="img"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroSection'
}
</script>
